import styles from './index.module.scss'
import {useEffect, useState} from "react";
import clsx from 'clsx';
import ProductDelivery from "./deliveryNew"
import Props from "../../../common/product/props";
import Button from "/components/common/button";
import {showPopup} from "../../../../redux/action-creaters/popup";
import {popupTypes} from "../../../../redux/reducers/popupReducer";
import {useDispatch, useSelector} from "react-redux";
import Reviews from "./reviews";
import Questions from "./questions";
import Files from "../files"
import Sert from "../sert";
import {useRouter} from "next/router";

const tabs = {
    description: 'description',
    delivery: 'delivery',
    reviews: 'reviews',
    questions: 'questions'
}

export default function Bottom({product, deliveryData, isLoadingDelivery, isBaltex}) {

    useEffect(() => {
        setTimeout(() => {
            if (typeof window === 'undefined') {
                return
            }
            Object.values(tabs).map(tab => {
                if (window.location.hash === `#${tab}`) {
                    const section = document.getElementById(tab);
                    section.click()
                    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
                }
            })
        }, 0);
    }, []);

    const [currentTab, setTab] = useState(tabs.description)

    const dispatch = useDispatch();

    const tabsNames = {
        [tabs.description]: 'Описание',
        [tabs.delivery]: 'Наличие и доставка',
        [tabs.reviews]: 'Отзывы',
        [tabs.questions]: 'Вопросы и ответы'
    }

    useEffect(() => {
        if (currentTab === tabs.delivery) {

        }
        if (currentTab === tabs.reviews) {
            if (typeof ym !== "undefined"){
                ym(20518312,'reachGoal','detailPageReviews')
            }
        }
        if (currentTab === tabs.questions) {
            if (typeof ym !== "undefined"){
                ym(20518312,'reachGoal','detailPageQuestions')
            }
        }
    }, [currentTab])


    const {sert} = useSelector(state => state.product)


    const router = useRouter()
    useEffect(() => {
        if (router.query.review) {
            dispatch(showPopup(popupTypes.addReview, {
                id: product.ID
            }))
        }
    }, []);

    return (
        <div className={clsx(isBaltex && styles.InfoBaltex, styles.Info)}>
            <div className="container">
                <div className={styles.Sections}>
                    {Object.values(tabs).map((tab) => (
                        <span
                            onClick={() => {
                                setTab(tab)
                            }}
                            key={tab}
                            id={tab}
                            className={clsx(currentTab === tab && styles.Selected)}
                        >
                            {tabsNames[tab]}
                            {(tab === tabs.reviews && product.REVIEWS > 0) ? ` (${product.REVIEWS})` : ''}
                            {(tab === tabs.questions && product.QUESTIONS > 0) ? ` (${product.QUESTIONS})` : ''}
                        </span>
                    ))}
                </div>

                {currentTab === tabs.description && (
                    <div>
                        {product['DETAIL_TEXT'] ? (
                            <div className={'text'}>
                                <div itemProp="description"
                                     dangerouslySetInnerHTML={{__html: product['DETAIL_TEXT']}}></div>
                            </div>
                        ) : (
                            <div>
                                <p>Нет описания. Если у вас появились вопросы по товару - закажите обратный звонок:</p>
                                <div>
                                    <Button customStyles={styles.FormButton}
                                            onClick={() => dispatch(showPopup(popupTypes.backCall))}
                                            label={'Заказать звонок'}/>
                                </div>
                            </div>
                        )}
                        {(product.PROPERTIES.length > 0 || product.FILE) && (
                            <div className={styles.Bottom}>

                                {product.PROPERTIES.length > 0 && (
                                    <div className={styles.Props}>
                                        <div className={styles.SubTitle}>Характеристики</div>
                                        <Props props={product.PROPERTIES} filterUrl={product.FILTER_URL}/>
                                    </div>
                                )}
                                {product.FILE && (
                                    <div className={styles.Docs}>
                                        <div className={styles.SubTitle}>Комплектация</div>
                                        <ul>
                                            <li>{product.NAME}{product.ARTICLE?.VALUE && (` (арт: ${product.ARTICLE?.VALUE})`)}{product.BRAND?.name && (`, ${product.BRAND?.name}`)}</li>
                                            {product.FILE && (<li><Files file={product.FILE}/></li>)}
                                            {product.CERT.length > 0 && (<li><Sert sert={product.CERT}/></li>)}
                                            <li>Комплект метизов для установки</li>
                                        </ul>

                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}

                {currentTab === tabs.delivery && (
                    <ProductDelivery product={product} deliveryData={deliveryData}
                                     isLoadingDelivery={isLoadingDelivery}/>
                )}

                {currentTab === tabs.reviews && (<div className={styles.Reviews}>
                    {product.REVIEWS > 0 ? (
                        <Reviews productId={product.ID}/>
                    ) : (
                        <p>Пока нет ни одного отзыва, будьте первым!</p>
                    )}
                    <Button
                        customStyles={styles.FormButton}
                        onClick={() => dispatch(showPopup(popupTypes.addReview, {
                            id: product.ID
                        }))}
                        label={'Оставить отзыв о товаре'}
                    />
                </div>)}

                {currentTab === tabs.questions && (<div className={styles.Questions}>
                    <div>
                        {product.QUESTIONS > 0 ? (
                            <Questions productId={product.ID}/>
                        ) : (
                            <p>Пока нет ни одного вопроса, будьте первым!</p>
                        )}

                        <Button
                            customStyles={styles.FormButton}
                            onClick={() => dispatch(showPopup(popupTypes.addQuestion, {
                                id: product.ID
                            }))}
                            label={'Задать вопрос по товару'}
                        />
                    </div>
                </div>)}
            </div>
        </div>
    )
}

