import styles from "./index.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {popupTypes} from "../../redux/reducers/popupReducer";
import Auth from './auth'
import BackCall from './backCall'
import Cart from './cart'
import FastView from './fastView'
import FindLowPrice from './findLowPrice'
import LocationNew from './locationNew'
import BuyOneClick from './buyOneClick'
import {showPopup} from "../../redux/action-creaters/popup";
import {useEffect} from "react";
import Icon from "../common/icon";
import AddQuestion from "./addQuestion";
import AddReview from "./addReview";
import AddComment from "./addComment";
import ServiceOrder from "./serviceOrder"
import ServiceQuestion from "./serviceQuestion"
import Video from "./video";
import SubscribeProduct from "./subscribeProduct";
import ProductVideo from "./productVideo";
import ProductReviews from "./productReviews";
import ProductQuestions from "./productQuestions";
import Slider from "./slider";
import FastViewService from "~/components/popups/fastViewService";
import OrderInstall from "~/components/popups/orderInstall";
import Scheme from "~/components/popups/scheme";
import Vacancy from "~/components/popups/vacancy";
import FeedBack from "~/components/popups/feedBack";
import Assortment from "~/components/popups/assortment";
import Message from "~/components/popups/message";
import clsx from "clsx";

export default function Popups() {
    const {visibleType} = useSelector(state => state.popup)
    const dispatch = useDispatch();

    useEffect( () => {
        const body = document.querySelector("body");
        if (visibleType === popupTypes.none) {
            body.classList.remove("popup");
        } else {
            body.classList.add("popup");
        }
    }, [visibleType] );

    const onHide = () => {
        dispatch(showPopup(popupTypes.none))
    }

    return visibleType !== popupTypes.none && (
        <noindex><span dangerouslySetInnerHTML={{__html:'<!--googleoff: all-->'}}/><div className={styles.Wrapper} onClick={onHide}>
            <div className={clsx(styles.Popup, 'popup')} onClick={e => e.stopPropagation()}>
                <div className={styles.Content}>
                    <span className={styles.Close} onClick={onHide}><Icon fill={'#333'} id='close' width={22} height={22}/></span>
                    {visibleType === popupTypes.auth && (
                        <Auth onHide={onHide}/>
                    )}
                    {visibleType === popupTypes.backCall && (
                        <BackCall onHide={onHide}/>
                    )}
                    {visibleType === popupTypes.cart && (
                        <Cart onHide={onHide}/>
                    )}
                    {visibleType === popupTypes.fastView && (
                        <FastView onHide={onHide}/>
                    )}
                    {visibleType === popupTypes.findLowPrice && (
                        <FindLowPrice onHide={onHide}/>
                    )}
                     {visibleType === popupTypes.locationNew && (
                        <LocationNew onHide={onHide}/>
                    )}
                    {visibleType === popupTypes.buyOneClick && (
                        <BuyOneClick onHide={onHide}/>
                    )}
                    {visibleType === popupTypes.addQuestion && (
                        <AddQuestion onHide={onHide}/>
                    )}
                    {visibleType === popupTypes.addReview && (
                        <AddReview onHide={onHide}/>
                    )}
                    {visibleType === popupTypes.addComment && (
                        <AddComment onHide={onHide}/>
                    )}
                    {visibleType === popupTypes.serviceOrder && (
                        <ServiceOrder onHide={onHide}/>
                    )}
                    {visibleType === popupTypes.orderInstall && (
                        <OrderInstall onHide={onHide}/>
                    )}
                    {visibleType === popupTypes.serviceQuestion && (
                        <ServiceQuestion onHide={onHide}/>
                    )}
                    {visibleType === popupTypes.video && (
                        <Video/>
                    )}
                    {visibleType === popupTypes.subscribeProduct && (
                        <SubscribeProduct/>
                    )}
                    {visibleType === popupTypes.slider && (
                        <Slider/>
                    )}
                    {visibleType === popupTypes.productVideo && (
                        <ProductVideo/>
                    )}
                    {visibleType === popupTypes.productReviews && (
                        <ProductReviews/>
                    )}
                    {visibleType === popupTypes.productQuestions && (
                        <ProductQuestions/>
                    )}
                    {visibleType === popupTypes.fastViewService && (
                        <FastViewService/>
                    )}
                    {visibleType === popupTypes.scheme && (
                        <Scheme/>
                    )}
                    {visibleType === popupTypes.vacancy && (
                        <Vacancy/>
                    )}
                    {visibleType === popupTypes.feedBack && (
                        <FeedBack/>
                    )}
                    {visibleType === popupTypes.assortment && (
                        <Assortment/>
                    )}
                    {visibleType === popupTypes.message && (
                        <Message/>
                    )}
                </div>
            </div>
        </div><span dangerouslySetInnerHTML={{__html:'<!--/googleoff: all-->'}}/></noindex>
    )
}
