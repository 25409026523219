import styles from './index.module.scss'
import {useEffect, useMemo, useState} from "react";
import {fetctQuestions} from "../../../../../redux/action-creaters/product";
import {useDispatch, useSelector} from "react-redux";
import Button from "/components/common/button";
import {showPopup} from "../../../../../redux/action-creaters/popup";
import {popupTypes} from "../../../../../redux/reducers/popupReducer";
import Comment from "../comment";
import Loader from "/components/common/loader"
import {useRouter} from "next/router";

export default function Questions({productId}) {
    const dispatch = useDispatch();
    const {questions} = useSelector(state => state.product)
    const [loading, setLoading] = useState(false)

    const router = useRouter();
    useEffect(async () => {
        setLoading(true)
        await dispatch(await fetctQuestions(productId,  Boolean(router.query.reset)))
        setLoading(false)
    }, [])

    return (
        <div>
            {loading && (
                <div className={styles.Loader}>
                    <Loader/>
                </div>
            )}
            {!loading && questions && questions?.map(question => (
                <div key={question.ID} className={styles.Item}>
                    <div className={styles.Author}>
                        <div className={styles.Ava}>
                            {question['NAME'][0]}
                        </div>
                        <div className={styles.Name}>
                            {question['NAME']}
                        </div>
                        <div className={styles.Date}>
                            {question['DATE']}
                        </div>
                        <div className={styles.Rating}>
                        </div>
                    </div>
                    <div className={styles.Block}>
                        {question['QUESTION'] && (
                            <div className={styles.Text}>
                                <span>Вопрос</span>
                                {question['QUESTION']}
                            </div>
                        )}

                        {question['ANSWER'] && <div className={styles.Comments}>
                            <Comment comment={question['ANSWER']}/>
                        </div>}

                    </div>
                </div>
            ))}
        </div>
    )
}