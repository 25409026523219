import {Api} from "../../api";
import {cacheFunctionData} from "../../utils";

export const fetchProduct = (code, host, reset = false) => {
    return async (dispatch) => {
        const response = await Api.server.getProduct(code, host, reset)
        dispatch({type: 'FETCH_PRODUCT', payload: response})
    }
}

export const fetchPhotos = (Redis = false, id, reset = false) => {
    return async (dispatch) => {
        const response = await Api.photo.getByProductId(Redis, id, reset)
        dispatch({type: 'FETCH_PHOTOS', payload: response})
    }
}

export const fetchReviews = (productId, isReset = false) => {
    return async (dispatch) => {
        const response = await Api.server.getProductReviews(productId, isReset)
        dispatch({type: 'FETCH_REVIEWS', payload: response})
    }
}

export const fetctQuestions = (productId, isReset = false) => {
    return async (dispatch) => {
        const response = await Api.server.getProductQuestions(productId, isReset)
        dispatch({type: 'FETCH_QUESTIONS', payload: response})
    }
}


export const fetchCertificateByBrand = (Redis, id, reset = false) => {
    return async (dispatch) => {
        const response = await Api.content.getCertificateByBrand(Redis, id, reset)
        dispatch({type: 'FETCH_SERT', payload: response})
    }
}

export const getEdost = (params) => {
    return async (dispatch) => {
        dispatch({type: 'SET_EDOST_LOADING', payload: true})
        const response = await Api.location.getEdost(params)
        dispatch({type: 'FETCH_EDOST', payload: response})
        dispatch({type: 'SET_EDOST_LOADING', payload: false})
    }
}


