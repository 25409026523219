import {useDispatch, useSelector} from "react-redux";
import styles from './index.module.scss'
import {Api} from "/api";
import {useEffect, useMemo, useState} from "react";
import Delivery from "/components/pages/product/delivery";
import Props from "/components/common/product/props";
import Offer from "/components/common/product/offer";
import Top from "/components/pages/product/top"
import Bottom from "/components/pages/product/bottom"
import BuyWithThis from "../buyWithThis"
import Files from "../files"
import Services from "../services"
import {fetchShops} from "@/redux/action-creaters/location";
import Images from "../images";
import clsx from "clsx";
import Share from "../share";
import Brand from "../brand";
import {formatType} from "../../../../utils";
import Sert from "../sert";
import BottomBaltex from "../bottomBaltex";

export default function ProductPage({product, photos, type = 'Каталог', isBaltex = false, isFarkop = false}) {
    const {cityName} = useSelector(state => state.location)
    const [deliveryData, setDeliveryData] = useState({})
    const [isLoadingDelivery, setIsLoadingDelivery] = useState(false)
    const {sections} = useSelector(state => state.sections)

    const dispatch = useDispatch();

    useEffect(async () => {
        setIsLoadingDelivery(true)
        const deliveryData = await Api.delivery.getProductsDeliveryNew(product.ID)
        setDeliveryData(deliveryData)
        setIsLoadingDelivery(false)
    }, [])

    useEffect(() => {
        if (cityName) {
            dispatch(fetchShops())
        }
    }, [cityName]);

    const images = useMemo(() => {
        return Object.values(photos).map(([thumb, small, full, scheme]) => {
            return {
                thumb,
                small,
                full,
                scheme
            }
        }).filter(item => {
            if (isBaltex) {
                return item.scheme === '#схема#'
            }
            return item;
        })
    }, [photos])
    
    const [managerComment, setManagerComment] = useState('')
    const [managerData, setManagerData] = useState('')

    const {user} = useSelector(state => state.user)
    useEffect(() => {
        if (user.IS_MANAGER) {
            const id = product.ID
            const getComments = async () => {
                const comment = await Api.catalog.getManagerComments([id]);
                setManagerComment(comment[id])
            }
            const getData = async () => {
                const managerData = await Api.catalog.getManagerData([id])
                setManagerData(managerData[id])
            }

            getComments();
            getData();
        }
    }, [user, product])

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            "ecommerce": {
                "currencyCode": "RUB",
                "detail": {
                    "products": [
                        {
                            "id": product.ID,
                            "name": product.NAME,
                            "price": product.CATALOG?.MIN_PRICE,
                            "category": sections[product.IBLOCK_SECTION_ID].NAME,
                            "brand": product.BRAND?.name,
                            "list": type,
                        }
                    ]
                }

            }
        })
    }, [])

    return (
        <article className={clsx(isBaltex && styles.ProductBaltex, styles.Product)} itemScope itemType="http://schema.org/Product">
            <div className="container">
                <h1 itemProp="name">{ formatType(product.NAME) }{!isBaltex && <span>Артикул: {product.ARTICLE?.VALUE}</span>}</h1>
                {!isBaltex && (
                    <Top
                        productId={product.ID}
                        article={product.ARTICLE?.VALUE}
                        reviews={product.REVIEWS}
                        isBaltex={isBaltex}
                        questions={product.QUESTIONS}
                    />
                )}

                <div className={styles.Body}>
                    <div className={styles.Left}>
                        <div id='productImages' className={styles.Photos}>
                            {product.STICK?.NAME && (
                                <p className={clsx(styles.Stick, styles[`Stick--${product.STICK?.CODE}`])}>{product.STICK?.NAME}</p>
                            )}
                            <Images images={images} video={product.VIDEO} alt={`${product.NAME} ${product.ARTICLE?.VALUE} в ${cityName}`}/>
                        </div>
                    </div>

                    <div className={styles.Right}>
                        <div className={styles.MainInfo}>

                            <div className={styles.Props}>

                                {Boolean(managerComment) && (
                                    <p style={{color: 'red'}}>{managerComment}<br/></p>
                                )}
                                {Boolean(managerData) && (
                                    <div className={styles.ManagerData}
                                         dangerouslySetInnerHTML={{__html: managerData}}/>
                                )}
                                {product.PROPERTIES.length > 0 && (
                                    <div className={styles.PropsBlock}>
                                        <div className={styles.PropsTitle}>Характеристики</div>
                                        <Props isGray={true} isBaltex={isBaltex} props={product.PROPERTIES}
                                               filterUrl={product.FILTER_URL}/>
                                    </div>
                                )}
                                <div
                                    className={styles.Link}
                                    onClick={() => {
                                        const section = document.getElementById('description');
                                        section.click()
                                        section.scrollIntoView({behavior: 'smooth', block: 'start'});
                                    }}
                                >Перейти к описанию
                                </div>
                                <div className={styles.FilesAndBrands}>
                                    <div className={styles.Files}>
                                        <Files file={product.FILE}/>
                                        <Sert sert={product.CERT}/>
                                    </div>
                                    {isFarkop && (
                                        <Brand data={product.BRAND}/>
                                    )}
                                </div>
                                <Share product={product} photo={images[0]?.['small']}/>
                            </div>
                            {product.CATALOG && <div>
                                <div className={styles.Buy}>
                                    <Offer
                                        key={product.ID}
                                        isDetail={true}
                                        name={product.NAME}
                                        product={product}
                                        productId={product.ID}
                                        catalog={product.CATALOG}
                                        isBaltex={isBaltex}
                                    />
                                </div>
                                {!isBaltex && product.CATALOG.AVAILABLE && (
                                    <div className={styles.Delivery}>
                                        <Delivery deliveryData={deliveryData} isBaltex={isBaltex}/>
                                    </div>
                                )}
                            </div>}
                        </div>
                        {!isBaltex && (
                            <>
                                <Services sectionId={product.IBLOCK_SECTION_ID} marka={product.MARKA?.CODE_ALT}/>
                                <BuyWithThis ids={product.BUY_WITH_THIS}/>
                            </>
                        )}
                    </div>
                </div>
            </div>

            {isBaltex && (
                <BottomBaltex product={product}/>
            )}
            {!isBaltex && (
                <Bottom isBaltex={isBaltex} product={product} deliveryData={deliveryData} isLoadingDelivery={isLoadingDelivery}/>
            )}


            {isBaltex && (
                <div className="container">
                    <BuyWithThis isBaltex={isBaltex}  ids={product.BUY_WITH_THIS}/>
                </div>
            )}
       </article>
    )
}