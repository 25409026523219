export default (axios, config) => ({
    async getCatalog(params, reset = false) {
        const {data} = await axios.get(`${config.API_SERVER_URL}/catalogServer/getCatalog?data=${encodeURI(JSON.stringify(params))}&reset=${reset}`);
        return data;
    },
    async getFilter(params, reset = false) {
        const {data} = await axios.get(`${config.API_SERVER_URL}/catalogServer/getFilter?data=${encodeURI(JSON.stringify(params))}&reset=${reset}`);
        return data;
    },
    async getServices(reset = false) {
        const {data} = await axios.get(`${config.API_SERVER_URL}/catalogServer/getServices?reset=${reset}`);
        return data;
    },
    async getServicesInstall(code, reset = false) {
        const {data} = await axios.get(`${config.API_SERVER_URL}/catalogServer/getServicesInstall?code=${code}&reset=${reset}`);
        return data;
    },
    async getSections(reset = false) {
        const {data} = await axios.get(`${config.API_SERVER_URL}/catalogServer/getSections?reset=${reset}`);
        return data;
    },
    async getDomains(reset = false) {
        const {data} = await axios.get(`${config.API_SERVER_URL}/catalogServer/getDomains?reset=${reset}`);
        return data;
    },
    async getBrands(reset = false) {
        const {data} = await axios.get(`${config.API_SERVER_URL}/catalogServer/getBrands?reset=${reset}`);
        return data;
    },
    async getProduct(code, host, reset = false) {
        const {data} = await axios.get(`${config.API_SERVER_URL}/catalogServer/getProduct?code=${code}&host=${host}&reset=${reset}`);
        return data;
    },
    async getProductReviews(productId, reset = false) {
        const {data} = await axios.get(`${config.API_SERVER_URL}/catalogServer/getProductReviews?productId=${productId}&reset=${reset}`);
        return data;
    },
    async getProductQuestions(productId, reset = false) {
        const {data} = await axios.get(`${config.API_SERVER_URL}/catalogServer/getProductQuestions?productId=${productId}&reset=${reset}`);
        return data;
    },
    async getCatalogBanners(reset = false) {
        const {data} = await axios.get(`${config.API_SERVER_URL}/catalogServer/getCatalogBanners?reset=${reset}`);
        return data;
    },
    async getEdostMinDays(cityName, kladr) {
        const {data} = await axios.get(`${config.API_SERVER_URL}/catalogServer/getEdostMinDays?cityName=${cityName}&kladr=${kladr}`);
        return data;
    },
    async getClientComments(page, marka, model, kuzov, host, reset = false) {
        const {data} = await axios.get(`${config.API_SERVER_URL}/catalogServer/getClientComments?page=${page}&marka=${marka}&model=${model}&kuzov=${kuzov}&host=${host}&reset=${reset}`);
        return data;
    },
    async getBaltexSections(reset = false) {
        const {data} = await axios.get(`${config.API_SERVER_URL}/catalogServer/getBaltexSections?reset=${reset}`);
        return data;
    },
    async getPromoVideo(code, host, reset = false) {
        const {data} = await axios.get(`${config.API_SERVER_URL}/catalogServer/getPromoVideo?reset=${reset}`);
        return data;
    },
});